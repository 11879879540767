import { useMemo } from 'react'
import { AdvertVisibilityType } from 'enums'

import useAdvertTitle from 'hooks/useAdvertTitle'

import { useAdvertTranslation } from '../Advert'

import { IAdvertCardProps } from './types'

const useAdvertCard = ({ ad, fullPathUrl, url }: IAdvertCardProps) => {
  const { t, lang } = useAdvertTranslation({
    ns: ['inputs', 'crm'],
    translation: ad.translation
  })

  const { title } = useAdvertTitle({
    ad,
    brandName: ad?.organisation?.brandName,
    lang
  })

  const href = useMemo(
    () =>
      ad.visibilityType === AdvertVisibilityType.Draft
        ? `/ad/edit/${ad.id}`
        : fullPathUrl ?? `${url}${ad.id}`,
    [ad, fullPathUrl, url]
  )
  const competency = useMemo(() => {
    if (!ad?.competencies.length) {
      return null
    }

    const [skill] = ad.competencies.sort((a, b) => {
      if (a.priority > b.priority) return 1
      if (a.priority < b.priority) return -1

      if (a.yearsOfExperience > b.yearsOfExperience) return -1
      if (a.yearsOfExperience < b.yearsOfExperience) return 1

      return 0
    })
    return skill ?? null
  }, [ad.competencies])

  return {
    t,
    title,
    href,
    competency
  }
}

export default useAdvertCard
