import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { useCallback, useMemo, useState } from 'react'
import SwiperClass from 'swiper/types/swiper-class'

import { IBadgeProps } from 'components/AdvertsPage/types'

import { useBadgesAmount } from 'hooks/useAdvert'
import useMediaQuery from 'hooks/useMediaQuery'

import { maxWidth } from 'utils'
import { RESPONSIVE } from 'constants/responsive.constant'

const useSubpages = ({ cities }: IBadgeProps) => {
  const isMobile = useMediaQuery(maxWidth(RESPONSIVE.sm))
  const { t } = useTranslation(['home_adverts'])
  const { locale } = useRouter()
  const { data } = useBadgesAmount({
    payload: { cities: cities.map((item) => item.cityvalue), locale }
  })

  const [isStart, setIsStart] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const updateStartAndEnd = useCallback(
    ({ isBeginning, isEnd }: SwiperClass) => {
      setIsStart(isBeginning)
      setIsEnd(isEnd)
    },
    []
  )

  const onUpdateSwiper = useCallback(
    (props: SwiperClass) => {
      updateStartAndEnd(props)
    },
    [updateStartAndEnd]
  )

  const path = useMemo(
    () => (locale == 'en' ? '/job-ads' : '/darbo-skelbimai'),
    [locale]
  )

  const subpages = useMemo(() => {
    const badges = cities
      .map((item) => ({
        link: `${path}/${item.cityvalue}`,
        text: `badges.city`,
        value: item.citybadge,
        count: data?.results[item.cityvalue] ?? 0
      }))
      .filter((item) => item.count)

    !isMobile &&
      badges.unshift({
        link: path,
        text: 'badges.allJobAds',
        value: '',
        count: data?.total ?? 0
      })

    return badges
  }, [cities, data?.results, data?.total, isMobile, path])

  return {
    subpages,
    isEnd,
    isStart,
    onUpdateSwiper,
    updateStartAndEnd,
    isMobile,
    t
  }
}

export default useSubpages
