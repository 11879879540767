import { useTranslation } from 'next-i18next'
import clsx from 'clsx'
import { ErrorMessage, Field } from 'formik'

import { getZodMessage } from 'utils/validation.util'

export const InputStyle = {
  fieldSimple:
    'focus:shadow-outline block h-full w-full appearance-none border-none bg-transparent py-2 px-4 text-gray-700 transition-all focus:shadow-transparent focus:outline-none',
  info: 'absolute text-xs tracking-wider antialiased pt-1',
  helper: 'fade-in text-gray-400 text-xs tracking-wider antialiased',
  error:
    'fade-in font-medium text-error-400 text-xs tracking-wider antialiased',
  input:
    'w-full rounded-sm border-0 border-b-2 border-gray-200 focus:border-gray-400 text-gray-700 transition-colors focus:border-black',
  label: 'block pt-4 pb-2 text-lg font-semibold tracking-wide text-gray-900',
  fieldWrap: 'relative',
  required: 'ml-1 font-bold text-red-550'
}

interface ITextAreaProps {
  label?: string
  name: string
  required?: boolean
  maxlength?: number
  helperText?: string
  placeholder?: string
  rows?: number
  cols?: number
  countSymbols?: number
  labelClasses?: string
  fieldClasses?: string
  className?: string
}

const TextArea = ({
  label,
  labelClasses,
  name,
  required,
  maxlength,
  helperText,
  placeholder,
  rows = 4,
  cols = 4,
  fieldClasses,
  countSymbols,
  className
}: ITextAreaProps) => {
  const { t } = useTranslation(['about_us'])

  return (
    <div className={clsx(InputStyle.fieldWrap, className)}>
      <label className={clsx(InputStyle.label, labelClasses)} htmlFor={name}>
        {label}
        {required && <span className={InputStyle.required}>*</span>}
      </label>
      <Field
        name={name}
        component="textarea"
        rows={rows}
        cols={cols}
        className={clsx(InputStyle.input, fieldClasses)}
        maxLength={maxlength}
        placeholder={placeholder}
      />
      {maxlength && (
        <span className="text-sm text-gray-400">
          {Number(countSymbols) >= 0
            ? `${countSymbols} / ${maxlength} ${t('lettersRemaining')}`
            : maxlength
            ? maxlength
            : ''}
        </span>
      )}
      <div className={InputStyle.info}>
        <div className={InputStyle.helper}>{helperText}</div>
        <ErrorMessage
          name={name}
          render={(msg) =>
            typeof msg === 'string' ? (
              <div className="fade-in text-medium text-error-400">
                {getZodMessage(msg, t(msg, { ns: 'zod' }))}
              </div>
            ) : null
          }
        />
      </div>
    </div>
  )
}

export default TextArea
