import { useTranslation } from 'next-i18next'
import clsx from 'clsx'

import { IPercentageViewProps } from './types'

const PercentageView = ({
  percentage,
  containerClassName = 'flex flex-col',
  matchTextClassName,
  percentageClassName
}: IPercentageViewProps) => {
  const { t } = useTranslation(['data'])

  if (percentage == null) {
    return null
  }

  return (
    <div className={clsx('ml-2 text-center text-gray-400', containerClassName)}>
      <span className={clsx('block font-medium', percentageClassName)}>
        {percentage}%
      </span>
      <span className={clsx('text-xs', matchTextClassName)}>
        {t('match', { ns: 'data' })}
      </span>
    </div>
  )
}

export default PercentageView
