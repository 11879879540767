import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import { FloatingPortal } from '@floating-ui/react'
import clsx from 'clsx'

import PercentageView from 'components/PercentageView'
import useContextMenu from 'components/selects/ContextMenu/useContextMenu'

import adUtils from 'utils/adUtil'
import { formatYearExperience } from 'utils/formaters.util'

import { IAdvertCardProps } from './types'
import useAdvertCard from './useAdvertCard'

const AdvertPortalCSR = dynamic(
  () => import('components/AdvertCard/components/AdvertPortal'),
  { ssr: false }
)

const AdvertCard = ({
  ad,
  percentage,
  className,
  url = '/ad/',
  fullPathUrl,
  swiper
}: IAdvertCardProps) => {
  const { title, t, href, competency } = useAdvertCard({
    ad,
    fullPathUrl,
    url
  })

  const { isOpen, refs, floatingStyles, getReferenceProps, getFloatingProps } =
    useContextMenu({ placement: 'top', mainAxis: false })

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps({
          className: 'bg-white z-[20]'
        })}
        onMouseEnter={() => {
          swiper?.autoplay.stop()
        }}
      >
        <Link
          data-cy={title}
          href={href}
          className={clsx(
            'group flex flex-col border-2 border-gray-400 bg-white p-4 transition-all hover:border-red-550 hover:text-red-550 active:border-red-550 active:text-red-550',
            className
          )}
        >
          <div className="flex justify-between">
            <div className="relative h-10 w-full">
              {ad.organisation &&
              ad.organisation.logoUrl &&
              ad.organisation.logoUrl[0] !== '{' ? (
                <Image
                  loading="lazy"
                  src={ad.organisation.logoUrl}
                  alt={`${ad.organisation.brandName} logo`}
                  layout="fill"
                  objectFit="contain"
                  objectPosition="left"
                  className="text-xs"
                />
              ) : null}
            </div>
            <PercentageView percentage={percentage} />
          </div>
          <div
            className={'my-3 h-12 font-semibold transition-colors line-clamp-2'}
          >
            {title}
          </div>
          {ad.appliedCandidates ? (
            <div className="h-[1.5em] text-sm text-gray-600 line-clamp-1">
              {t('project.candidates', { ns: 'crm' })}:{' '}
              {ad.appliedCandidates.totalCandidates}
              {!!ad.appliedCandidates.newCandidates && (
                <span className="font-medium text-red-550">
                  {' '}
                  +{ad.appliedCandidates.newCandidates}
                </span>
              )}
            </div>
          ) : (
            <>
              <div className="h-[1.5em] text-sm text-gray-600 line-clamp-1">
                {competency && (
                  <span>
                    {`${competency.skill} ${formatYearExperience(
                      competency.yearsOfExperience
                    )} ${t('years.label', { ns: ['inputs'] })}`}
                  </span>
                )}
              </div>
              <div className="text-sm text-gray-600">
                {adUtils.getAdvertSalary({
                  t,
                  from: ad.salaryFrom,
                  to: ad.salaryTo
                })}
              </div>
            </>
          )}
        </Link>
      </div>

      {isOpen && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              ref: refs.setFloating,
              className: 'bg-white shadow-lg z-[100] mt-32',
              style: floatingStyles
            })}
          >
            <AdvertPortalCSR
              ad={ad}
              showMoreLink={fullPathUrl ?? `${url}${ad.id}`}
              swiper={swiper}
            />
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

export default AdvertCard
